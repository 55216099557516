import { useEffect } from 'react';
import { fromEvent } from 'rxjs';
import { debounceTime, filter, map, pairwise } from 'rxjs/operators';
import { mappingWindowEvent, mappingElementEvent, filterAction } from './utils';

export default function useInfiniteScroll({
    fromWindow,
    source,
    hasNext,
    onLoadMore,
    debounce=200
}) {
    useEffect(() => {
        if (!hasNext) {
            return;
        }

        const el = fromWindow? window: source && source.current;

        if (!el) {
            return;
        }
        const mapFunction = fromWindow?mappingWindowEvent : mappingElementEvent;

        const scrollListener =fromEvent(el, 'scroll');
        const observer=  scrollListener.pipe(
            map(mapFunction),
            pairwise(),
            filter(filterAction),
            debounceTime(debounce)
        );
        const scrollSubscription = observer.subscribe(() => {
            if (hasNext) {
                onLoadMore && onLoadMore();
            }
        });
        return () => {
            scrollSubscription.unsubscribe();
        };

    }, [source, fromWindow, hasNext, onLoadMore, debounce]);
}
