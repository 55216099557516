export const mappingWindowEvent = () => {
    return {
        sH: document.getElementsByTagName('body')[0].clientHeight,
        sT: window.scrollY,
        cH: window.innerHeight
    };
};
export const mappingElementEvent = ({ target }) => {
    return {
        sH: target.scrollHeight,
        sT: target.scrollTop,
        cH: target.clientHeight
    };
};

/**
 check if the user is scrolling down by
 previous scroll position and current scroll position
 **/
const isUserScrollingDown = (positions) => {
    return positions[0].sT < positions[1].sT;
};

/** Check if the scroll position at required
 percentage relative to the container
 **/
export const isScrollExpectedPercent = (position, percent) => {
    return (position.sT + position.cH) / position.sH > percent / 100;
};

export const filterAction = (positions) => {
    return (
        isUserScrollingDown(positions) && isScrollExpectedPercent(positions[1], 70)
    );
};

