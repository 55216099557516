/* eslint-disable no-undef,prefer-promise-reject-errors */
import axios from 'axios'
import { Subject } from 'rxjs'
import { filter } from 'rxjs/operators'

class ApiClientService {
  constructor() {
    const API_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080'
    this.ACCESS_TOKKEN_KEY =
      process.env.REACT_APP_ACCESS_TOKKEN || '__access_token__'
    this.LANG_KEY = process.env.LANG_KEY || 'i18nextLng'
    const service = axios.create({
      baseURL: API_URL
    })
    this.defaultHeaders = {}
    const space = localStorage.getItem('x-workspace')
    this.setSpace(space)
    service.interceptors.response.use(this.handleSuccess, this.handleError)
    service.interceptors.request.use(this.onRequestSuccess)
    Object.assign(this, service)
    this.API_URL = API_URL
    this.service = service
    this.listener = new Subject()
  }

  setup({ TokenService, loginPath }) {
    this.TokenService = TokenService
    this.loginPath = loginPath
  }

  pushDefaultHeader(key, value) {
    this.defaultHeaders[key] = value
  }

  removeDefaultHeader(key) {
    delete this.defaultHeaders[key]
  }

  onRequestSuccess = (config) => {
    const token = this.getToken()
    const lan = this.getLan()

    config.headers = {
      'Content-Type': 'application/json',
      accept: 'application/json',

      ...this.defaultHeaders,
      ...config.headers
    }
    if (lan && !config.headers['Accept-Language']) {
      config.headers['Accept-Language'] = lan
    }
    if (token && !config.ignoreToken) {
      config.headers.Authorization = `Bearer ${token}`
    }
    config.timeout = process.env.REACT_APP_TIMEOUT || 1000000
    if (!config.url.startsWith('http'))
      config.url = `${this.API_URL.replace(/\/$/, '')}${config.url}`
    return config
  }

  onError(callback, statusCode) {
    this.listener
      .pipe(
        filter(
          (error) =>
            !statusCode ||
            (error.response && error.response.status === statusCode)
        )
      )
      .subscribe(callback)
  }

  handleSuccess(response) {
    return response
  }

  handleError = async (err) => {
    const originalConfig = err.config
    let error = err
    if (this.TokenService && this.getToken()) {
      const isNotLogin = this.loginPath ? !originalConfig.url.match(this.loginPath) : false
      if (isNotLogin && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true
          try {
            await this.TokenService.refresh()
            return this.service(originalConfig)
          } catch (_error) {
            error = _error
          }
        }
      }
    }
    this.listener.next(error)
    return Promise.reject({
      status: error.response && error.response.status,
      response: error.response || error
    })
  }

  uploadRequest(url, opts = {}, onProgress) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.open(opts.method || 'get', url)
      for (const k in opts.headers || {})
        xhr.setRequestHeader(k, opts.headers[k])
      xhr.onload = (e) => {
        let result = null
        if (e.target && e.target.status === 200) {
          const parseJSON = JSON.parse(e.target._response)
          result = {
            status: e.target.status,
            ...parseJSON
          }
          resolve(result)
        } else
          reject({
            status: e.target.status
          })
      }
      xhr.onerror = reject
      if (xhr.upload && onProgress) xhr.upload.onprogress = onProgress // event.loaded / event.total * 100 ; //event.lengthComputable
      xhr.send(opts.body)
    })
  }

  getToken() {
    return (
      localStorage.getItem(this.ACCESS_TOKKEN_KEY) ||
      sessionStorage.getItem(this.ACCESS_TOKKEN_KEY)
    )
  }

  getHeader(key) {
    return this.defaultHeaders[key]
  }

  getSpace() {
    return this.getHeader('x-workspace')
  }

  setSpace(space) {
    if (space && space !== 'null') {
      localStorage.setItem('x-workspace', space)
      return this.pushDefaultHeader('x-workspace', space)
    }
    this.removeSpace()
  }

  removeSpace() {
    localStorage.removeItem('x-workspace')
    this.removeDefaultHeader('x-workspace')
  }

  getLan() {
    const lang =
      localStorage.getItem(this.LANG_KEY) ||
      sessionStorage.getItem(this.LANG_KEY)
    if (lang) return lang.split('-')[0]
  }

  setToken(token, remember = true) {
    return (remember ? localStorage : sessionStorage).setItem(
      this.ACCESS_TOKKEN_KEY,
      token
    )
  }
}

export default new ApiClientService() // making it singlenton
